 @use 'variables' as var;

 /*--------------------------------------------------------------
# IMPORTANT
Il des style rewrite de text-editor dans _text-editor.scss
# IMPORTANT
--------------------------------------------------------------*/
 html {
 	font-size: var.$font__size-body;
 	font-style: normal;
 }
 
 body,
 button,
 input,
 select,
 textarea {
 	color: var.$color__black;
 	font-family: var.$font__main;
 	font-weight: var.$font__weight-regular;
 	font-size: var.$font__size-body;
 	line-height: var.$font__line-height-body;
 }
 
 /*--------------------------------------------------------------
 ## Font vertical align correction for Poppins
 --------------------------------------------------------------*/
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 p,
 span {
 	// position: relative;
 	// top: var.$font__top-position-correction;
 }
 
 span span,
 p span,
 h1 span,
 h2 span,
 h3 span,
 h4 span,
 h5 span,
 h6 span,
 a span {
 	// position: initial;
 	// top: inherit;
 }
 
 /*--------------------------------------------------------------
 ## Heading
 --------------------------------------------------------------*/
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
 	font-family: var.$font__heading;
 	color: var.$color__black;
 	margin-bottom: 0.75rem;
 	margin-top: 0px;
 	clear: both;
 }
 
 h1 {
 	font-size: 1.875rem;
 	line-height: 0.9454em;
 	letter-spacing: 0.0192em;

 	@media (screen(md)) {
 		font-size: 2.125rem;
 	}
 }
 
 h2 {
 	font-size: 1.5rem;
 	line-height: 1.1666em;

 	@media (screen(md)) {
 		font-size: 1.625rem;
 	}
 }
 
 h3 {
 	font-size: 1.25rem;
 	line-height: 1.0769em;

 	@media (screen(md)) {
 		font-size: 1.375rem;
 	}
 }
 
 h4 {
 	font-size: 1.125rem;
 	line-height: 1.0769em;

 	@media (screen(md)) {
 		font-size: 1.25rem;
 	}
 }
 
 h5 {
 	font-size: 1rem;
 	line-height: 1.125em;
 }
 
 h6 {
 	font-size: 1rem;
 	line-height: 1.0769em;
 }
 
 p {
 	font-size: 1rem;
 	line-height: 1.2em;
 	margin-bottom: 0.75em;
 }
 
 .text-tiny,
 .text-small,
 .text-big,
 .text-huge {
 	line-height: 1.25em;
 }
 
 blockquote {}
 
 /*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/