/*******************************************************
	Color Overlay
*******************************************************/
.overlay-red {
	background-color: rgba(187, 43, 44, 0.6);
}

.overlay-purple {
	background-color: rgba(124, 34, 68, 0.6);
}

.overlay-yellow {
	background-color: rgba(175, 107, 3, 0.6);
}