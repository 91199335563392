/*******************************************************
	Container & Grid
*******************************************************/
.container {
	max-width: none;

	@media (screen(sm)) {
		max-width: none;
	}
	
	@media (screen(md)) {
		max-width: none;
	}
	
	@media (screen(lg)) {
		max-width: none;
	}
	
	@media (screen(xl)) {
		max-width: 1280px;
	}
	
	@media (screen(xl)) {
		max-width: 1536px;
	}
}

.grid {
	gap: 1.5rem;
}