/***********************************
	Shadow
***********************************/
.custom-shadow-md {
	box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.15);

	&:hover {
		box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.25);
	}
}

.custom-shadow-lg {
	box-shadow: 0px 0px 12px 8px rgba(0, 0, 0, 0.25);
}