// Blocks
html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
    font-weight: 400;
}

// List
ul {
    list-style: none;
}

// Form
button,
input,
select,
textarea {
    margin: 0;
    border: 0;
    background-image: none;
    background-color: transparent;
    box-shadow: none;

    &:focus,
    &:active {
        outline: none;
    }
}

// Box sizing
html {
    box-sizing: border-box;
}

* {
    box-sizing: inherit;

    &,
    &::before,
    &::after {
        box-sizing: inherit;
    }
}

// Media
img,
video {
    height: auto;
    max-width: 100%;
}

// Iframe
iframe {
    border: 0;
}

// Table
table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;

    &:not([align]) {
        text-align: inherit;
    }
}

address {
    font-style: initial;
}
