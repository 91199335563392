/*--------------------------------------------------------------
## General styles
--------------------------------------------------------------*/
.text-editor {

	ul{
		list-style: disc;
		color: inherit;
		padding-left: 20px;

		li {
			padding-bottom: 8px;

			ul,
			ol {
				padding-top: 12px;
			}
		}
	}

	ol{
		list-style: auto;
		color: inherit;
		padding-left: 20px;

		li {
			padding-bottom: 8px;

			ul,
			ol {
				padding-top: 12px;
			}
		}
	}
}