 @use 'variables' as var;

 /*--------------------------------------------------------------
## FORMS General
## Formie
--------------------------------------------------------------*/
 /*-------------------------------------
    PLACEHOLDER
------------------------------------*/
 ::-webkit-input-placeholder {
 	/* Chrome */
 	// color: var.$color__grey-medium;
 	opacity: 1;
 }
 
 :-ms-input-placeholder {
 	/* IE 10+ */
 	// color: var.$color__grey-medium;
 }
 
 ::-moz-placeholder {
 	/* Firefox 19+ */
 	// color: var.$color__grey-medium;
 }
 
 :-moz-placeholder {
 	/* Firefox 4 - 18 */
 	// color: var.$color__grey-medium;
 }
 
 /*--------------------------------------------------------------
## Fields
--------------------------------------------------------------*/
 input[type="text"],
 input[type="text"].fui-input,
 input[type="email"],
 input[type="email"].fui-input,
 input[type="url"],
 input[type="url"].fui-input,
 input[type="password"],
 input[type="password"].fui-input,
 input[type="search"],
 input[type="search"].fui-input,
 input[type="number"],
 input[type="number"].fui-input,
 input[type="tel"],
 input[type="tel"].fui-input,
 input[type="range"],
 input[type="range"].fui-input,
 input[type="date"],
 input[type="date"].fui-input,
 input[type="month"],
 input[type="month"].fui-input,
 input[type="week"],
 input[type="week"].fui-input,
 input[type="time"],
 input[type="time"].fui-input,
 input[type="datetime"],
 input[type="datetime"].fui-input,
 input[type="datetime-local"],
 input[type="datetime-local"].fui-input,
 input[type="color"],
 input[type="color"].fui-input,
 textarea,
 textarea.fui-input,
 select,
 select.fui-select {
 	width: 100%;
 	color: var.$color__black;
 	font-size: var.$font__size-14;
 	border: none;
 	border-radius: 0px;
 	padding: 10px;
 	height: 45px;
 	background-color: var.$color__eggshell;

 	&:focus {
 		// box-shadow: 0 0 3px 1px var.$color__green-success-25;
 	}
 	
 	&.fui-error {

 		// border-color: var.$color__red-error;
 		&:focus {
 			// box-shadow: 0 0 3px 1px var.$color__red-error-25;
 		}
 	}
 }
 
 textarea {
 	width: 100%;
 	min-height: 225px;
 }
 
 /*--------------------------------------------------------------
## Labels & legend
--------------------------------------------------------------*/
 .fui-form {
 	.fui-field {
 		.fui-field-container {

 			label.fui-label,
 			.fui-legend,
 			legend {
 				color: var.$color__grey-dark !important;
 				font-weight: var.$font__weight-semibold !important;
 				font-size: var.$font__size-body !important;
 			}
 		}
 		
 		&.fui-error {
 			.fui-field-container {
 				label.fui-label {
 					// color: var.$color__red-error;
 				}
 			}
 		}
 	}
 }
 
 .fui-legend,
 legend {
 	color: var.$color__grey-dark !important;
 	font-weight: var.$font__weight-semibold !important;
 	font-size: var.$font__size-body !important;
 }
 
 /*--------------------------------------------------------------
 ## Fui-Row
 --------------------------------------------------------------*/
 .fui-row {
 	margin-bottom: 25px !important;
 }
 
 /*--------------------------------------------------------------
## Checkbox
--------------------------------------------------------------*/
 .fui-form {
 	.fui-radio {
 		.fui-radio-input {
 			appearance: none;
 			display: none
 		}
 		
 		.fui-radio-label {
 			position: relative;
 			color: var.$color__grey-dark !important;
 			font-weight: var.$font__weight-regular !important;
 			font-size: var.$font__size-body !important;

 			&:before {
 				position: absolute;
 				top: 2px;
 				left: 0px;
 				background-color: transparent;
 				border: 1px solid var.$color__grey-medium;
 				border-radius: 50%;
 				height: 1.5rem;
 				width: 1.5rem;
 			}
 			
 			&:after {
 				position: absolute;
 				display: none;
 				top: 7px;
 				left: 7px;
 				height: 0.75rem;
 				width: 0.5rem;
 				transform: rotate(35deg);
 				border-right: 3px solid var.$color__white;
 				border-bottom: 3px solid var.$color__white;
 			}
 		}
 		
 		.fui-radio-input {
 			&:checked+label {
 				&:before {
 					border-color: var.$color__white;
 					background-color: var.$color__wine;
 					background-image: none;
 				}
 				
 				&:after {
 					display: block;
 					content: "";
 					border-color: var.$color__white;
 					background-image: none;
 				}
 			}
 			
 			&:focus+label {
 				&:before {
 					border-color: var.$color__wine;
 					box-shadow: 0 0 3px 1px var.$color__wine;
 				}
 			}
 		}
 	}
 	
 	.fui-checkbox {
 		.fui-radio-input {
 			appearance: none;
 			display: none
 		}
 		
 		.fui-checkbox-label {
 			position: relative;
 			color: var.$color__grey-dark !important;
 			font-weight: var.$font__weight-regular !important;
 			font-size: var.$font__size-body !important;

 			&:before {
 				position: absolute;
 				top: 2px;
 				left: 0px;
 				background-color: transparent;
 				border: 1px solid var.$color__grey-medium;
 				height: 1.5rem;
 				width: 1.5rem;
 			}
 			
 			&:after {
 				position: absolute;
 				display: none;
 				top: 7px;
 				left: 7px;
 				height: 0.75rem;
 				width: 0.5rem;
 				transform: rotate(35deg);
 				border-right: 3px solid var.$color__white;
 				border-bottom: 3px solid var.$color__white;
 			}
 		}
 		
 		.fui-checkbox-input {
 			&:checked+label {
 				&:before {
 					border-color: var.$color__white;
 					background-color: var.$color__wine;
 					background-image: none;
 				}
 				
 				&:after {
 					display: block;
 					content: "";
 					border-color: var.$color__white;
 					background-image: none;
 				}
 			}
 			
 			&:focus+label {
 				&:before {
 					border-color: var.$color__wine;
 					box-shadow: 0 0 3px 1px var.$color__wine;
 				}
 			}
 		}
 	}
 }
 
 .radio-input-wrapper {
 	position: relative;

 	input[type="radio"] {
 		display: none;
 	}
 	
 	label {
 		width: 100%;
 		text-align: center;
 		color: var.$color__purple-dark;
 		margin-left: 0rem;
 		border-top: 1px solid var.$color__purple-dark;
 		border-right: 1px solid var.$color__purple-dark;
 		border-left: 1px solid var.$color__purple-dark;
 		cursor: pointer;

 		@media (screen(md)) {
 			border: 1px solid var.$color__purple-dark;
 		}
 		
 		&:hover {
 			background-color: var.$color__purple-dark;
 			color: var.$color__white;
 		}
 	}
 	
 	input[type="radio"] {
 		&:checked+label {
 			background-color: var.$color__purple-dark;
 			color: var.$color__white;
 		}
 		
 		&:focus+label {
 			&:before {
 				// border-color: var.$color__green-primary;
 				// box-shadow: 0 0 3px 1px var.$color__green-primary;
 			}
 		}
 	}
 }
 
 .radio-input-parent {
 	&:last-of-type {
 		label {
 			border-bottom: 1px solid var.$color__purple-dark;
 		}
 	}
 }
 
 /*--------------------------------------------------------------
## Select
--------------------------------------------------------------*/
 .select-input-wrapper {
 	position: relative;
 	width: 100%;
 	background-color: var.$color__white;

 	&:before,
 	&:after {
 		position: absolute;
 		display: block;
 		content: "";
 		top: 50%;
 		width: 8px;
 		height: 2px;
 		// background-color: var.$color__green-primary;
 		pointer-events: none;
 	}
 	
 	&:before {
 		right: 13px;
 		transform: rotate(-45deg);
 	}
 	
 	&:after {
 		right: 18px;
 		transform: rotate(45deg);
 	}
 	
 	select {
 		width: 100%;
 		min-width: 100%;
 		appearance: none;
 		color: var.$color__black;
 		border: 0px;
 		height: 55px;
 		background-color: transparent;
 		padding: 18px 30px 10px 10px;
 		border-radius: 0;

 		option {
 			color: var.$color__black !important;
 			letter-spacing: 1px;
 		}
 	}
 	
 	&.slim {
 		position: relative;
 		top: 2px;
 		width: fit-content;
 		background-color: transparent;

 		&:before,
 		&:after {
 			top: 43%;
 		}
 		
 		// @include media-breakpoint-up(sm) {
 		// 	width: auto;
 		// }
 		select {
 			font-size: var.$font__size-14;
 			width: auto;
 			min-width: unset;
 			height: 24px;
 			padding: 0px 35px 0px 0px;
 		}
 	}
 }
 
 /*--------------------------------------------------------------
## Files
--------------------------------------------------------------*/
 .fui-type-file-upload {
 	.fui-field-container {
 		position: relative;

 		label {
 			position: absolute;
 			overflow: auto;
 			clip: unset;
 			width: 100%;
 			text-align: center;
 			font-size: 1.3125em;
 			font-weight: var.$font__weight-medium;
 			height: auto;
 			top: 20px;
 			display: block;
 			z-index: 2;
 			pointer-events: none;
 		}
 		
 		.fui-input-container {
 			position: relative;
 			display: flex;
 			gap: 10px;
 			flex-direction: column;
 			justify-content: center;
 			align-items: center;
 			height: 150px;
 			padding: 40px 20px 20px 20px;
 			background-color: var.$color__white;
 			border: 2px solid var.$color__white;
 			cursor: pointer;
 			transition: background .2s ease-in-out;

 			input[type="file"] {
 				display: flex;
 				flex-direction: column;
 				justify-content: center;
 				width: 100%;
 				max-width: 100%;
 				color: var.$color__black;
 				padding: 5px;
 				background: transparent;
 				border: none;

 				&::file-selector-button {
 					display: flex;
 					// flex-direction: column;
 					justify-content: center;
 					text-align: center;
 					width: 100%;
 					margin-right: 20px;
 					font-weight: var.$font__weight-bold;
 					text-decoration: underline;
 					border: none;
 					background: transparent;
 					padding: 10px 20px;
 					// color: var.$color__green-primary;
 					cursor: pointer;

 					&:hover {
 						// background: #0d45a5;
 					}
 				}
 				
 				&:focus {
 					border-color: transparent;
 					box-shadow: none;
 					outline: 0;
 				}
 			}
 			
 			&:hover {

 				// background: var.$color__grey-light;
 				// border-color: #111;
 				.drop-title {
 					// color: #222;
 				}
 			}
 		}
 	}
 }
 
 // input[type=file]::file-selector-button {
 //   margin-right: 20px;
 //   border: none;
 //   background: #084cdf;
 //   padding: 10px 20px;
 //   border-radius: 10px;
 //   color: #fff;
 //   cursor: pointer;
 //   transition: background .2s ease-in-out;
 // }
 input[type=file]::file-selector-button:hover {
 	// background: #0d45a5;
 }
 
 .fui-form {
 	.fui-form-container {
 		.fui-btn-wrapper {
 			button[type="submit"].fui-submit {
 				font-size: 16px !important;
 				padding: 12px 30px 11px 30px !important;
 				background-color: var.$color__whine;
 				border: 1px solid var.$color__whine;
 				border-radius: 0px;

 				&:hover {
 					border-color: var.$color__grey-dark;
 					background-color: var.$color__grey-dark;
 				}
 				
 				&:focus {
 					color: var.$color__grey-dark;
 					border: 2px solid var.$color__grey-dark;
 				}
 			}
 		}
 	}
 }
 
 /*--------------------------------------------------------------
## Required
--------------------------------------------------------------*/
 .fui-form {
 	.fui-field {
 		.fui-field-container {
 			.fui-required {
 				// color: var.$color__red-error;
 			}
 		}
 	}
 }
 
 /*--------------------------------------------------------------
## Error messages
--------------------------------------------------------------*/
 .fui-form {
 	.fui-field {
 		.fui-field-container {
 			.fui-error-message {
 				// color: var.$color__red-error;
 			}
 		}
 	}
 }
 
 /*--------------------------------------------------------------
## Specific form
--------------------------------------------------------------*/
 .fui-form {
 	.fui-form-container {
 		.fui-page {
 			.fui-page-container {
 				.fui-page-row {
 					.fui-field-container {
 						label.fui-label {}
 						
 						.fui-input-container {}
 					}
 				}
 			}
 		}
 	}
 }